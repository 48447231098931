import { useState } from "react";

import { styled } from "@mui/material/styles";
import {
  Box,
  TextField,
  useTheme,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { useTranslation } from "react-i18next";

const NEW_CLIENT_URL = "/clients";
const today = dayjs;

const NewClient = (props) => {
  const { handleClose, setAlertMessage, setOpenAlert, setSeverity } = props;
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [store, setStore] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");

  const [passportNumber, setPassportNumber] = useState("");
  const [passportExpiration, setPassportExpiration] = useState(null);
  const [driverLicense, setDriverLicense] = useState("");
  const [driverLicenseExpiration, setDriverLicenseExpiration] = useState(null);

  const [SSN_ITIN, setSSN_ITIN] = useState("");
  const [otherInformations, setOtherInformations] = useState("");

  const formData = new FormData();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack

    if (!name || !phoneNumber) {
      setAlertMessage(t("clients.newClient.alertMessage1"));
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    formData.append("name", name);
    formData.append("phoneNumber", phoneNumber);
    if (birthDate) {
      formData.append("birthDate", dayjs(birthDate).format("YYYY-MM-DD"));
    }
    if (email) formData.append("email", email);
    if (address) formData.append("address", address);
    if (city) formData.append("city", city);
    if (state) formData.append("state", state);
    if (zipCode) formData.append("zipCode", zipCode);
    if (country) formData.append("country", country);

    if (passportNumber) formData.append("passportNumber", passportNumber);
    if (passportExpiration) {
      formData.append(
        "passportExpiration",
        dayjs(passportExpiration).format("YYYY-MM-DD")
      );
    }
    if (driverLicense) formData.append("driverLicense", driverLicense);
    if (driverLicenseExpiration) {
      formData.append(
        "driverLicenseExpiration",
        dayjs(driverLicenseExpiration).format("YYYY-MM-DD")
      );
    }
    if (SSN_ITIN) formData.append("SSN_ITIN", SSN_ITIN);

    /*if (socialSecurityNumber)
      formData.append("socialSecurityNumber", socialSecurityNumber);
    if (individualTaxpayerIdentificationNumber)
      formData.append(
        "individualTaxpayerIdentificationNumber",
        individualTaxpayerIdentificationNumber
      );
*/
    if (otherInformations)
      formData.append("otherInformations", otherInformations);

    try {
      const response = await axiosPrivate.post(NEW_CLIENT_URL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        credentials: "include",
        withCredentials: true,
        data: formData,
      });

      if (response.status === 201) {
        setAlertMessage(t("clients.newClient.successAlertMessage"));
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(t("clients.newClient.serverFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage(t("clients.newClient.otherFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Box>
        <Header
          title={t("clients.newClient.header.title")}
          subtitle={t("clients.newClient.header.subtitle")}
          button={false}
          color="#004549"
        />
        <Box
          borderRadius="4px"
          marginTop="12px"
          padding="8px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gap="8px"
          gridTemplateColumns="repeat(6, 1fr)"
        >
          <TextField
            id="name"
            label={t("clients.form.name")}
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            sx={{
              gridColumn: isNonMobile ? "span 4" : "span 3",
              backgroundColor: colors.primary[100],
            }}
          />
          <TextField
            id="phoneNumber"
            label={t("clients.form.phoneNumber")}
            variant="outlined"
            value={[phoneNumber]}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            sx={{
              gridColumn: isNonMobile ? "span 2" : "span 3",
              backgroundColor: colors.primary[100],
            }}
          />
          <TextField
            id="email"
            label={t("clients.form.email")}
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              gridColumn: isNonMobile ? "span 4" : "span 3",
              backgroundColor: colors.primary[100],
            }}
          />
          <DatePicker
            id="birthDate"
            variant="outlined"
            disableFuture
            label={t("clients.form.birthDate")}
            defaultValue={today}
            value={birthDate}
            onChange={(newValue) => setBirthDate(newValue)}
            sx={{
              gridColumn: isNonMobile ? "span 2" : "span 3",
              backgroundColor: colors.primary[100],
            }}
            format="YYYY-MM-DD"
          />
          <TextField
            id="address"
            label={t("clients.form.address")}
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            sx={{
              gridColumn: isNonMobile ? "span 4" : "span 3",
              backgroundColor: colors.primary[100],
            }}
          />
          <TextField
            id="zipCode"
            label={t("clients.form.zipCode")}
            variant="outlined"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            sx={{
              gridColumn: isNonMobile ? "span 2" : "span 3",
              backgroundColor: colors.primary[100],
            }}
          />
          <TextField
            id="city"
            label={t("clients.form.city")}
            variant="outlined"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            sx={{
              gridColumn: "span 2",
              backgroundColor: colors.primary[100],
            }}
          />
          <TextField
            id="state"
            label={t("clients.form.state")}
            variant="outlined"
            value={state}
            onChange={(e) => setState(e.target.value)}
            sx={{
              gridColumn: "span 2",
              backgroundColor: colors.primary[100],
            }}
          />
          <TextField
            id="country"
            label={t("clients.form.country")}
            variant="outlined"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            sx={{
              gridColumn: "span 2",
              backgroundColor: colors.primary[100],
            }}
          />
          <TextField
            id="passportNumber"
            label={t("clients.form.passportNumber")}
            variant="outlined"
            value={passportNumber}
            onChange={(e) => setPassportNumber(e.target.value)}
            sx={{
              gridColumn: isNonMobile ? "span 2" : "span 3",
              backgroundColor: colors.primary[100],
            }}
          />
          <DatePicker
            id="passportExpiration"
            disabled={!passportNumber}
            label={t("clients.form.passportExpiration")}
            variant="outlined"
            value={passportExpiration}
            onChange={(e) => setPassportExpiration(e)}
            sx={{
              gridColumn: isNonMobile ? "span 1" : "span 3",
              backgroundColor: colors.primary[100],
            }}
            format="YYYY-MM-DD"
          />
          <TextField
            id="driverLicense"
            label="Driver License"
            variant="outlined"
            value={driverLicense}
            onChange={(e) => setDriverLicense(e.target.value)}
            sx={{
              gridColumn: isNonMobile ? "span 2" : "span 3",
              backgroundColor: colors.primary[100],
            }}
          />
          <DatePicker
            id="driverLicenseExpiration"
            label="Validade da Driver License"
            disabled={!driverLicense}
            variant="outlined"
            value={driverLicenseExpiration}
            onChange={(e) => setDriverLicenseExpiration(e)}
            sx={{
              gridColumn: isNonMobile ? "span 1" : "span 3",
              backgroundColor: colors.primary[100],
            }}
            format="YYYY-MM-DD"
          />

          <TextField
            id="socialSecurityNumberITIN"
            label="SSN/ITIN"
            variant="outlined"
            value={SSN_ITIN}
            onChange={(e) => setSSN_ITIN(e.target.value)}
            sx={{
              gridColumn: isNonMobile ? "span 3" : "span 6",
              backgroundColor: colors.primary[100],
            }}
          />
          {/*
          <TextField
            id="socialSecurityNumber"
            label={t("clients.form.socialSecurityNumber")}
            variant="outlined"
            value={socialSecurityNumber}
            onChange={(e) => setSocialSecurityNumber(e.target.value)}
            sx={{
              gridColumn: isNonMobile ? "span 3" : "span 6",
              backgroundColor: colors.primary[100],
            }}
          />

          <TextField
            id="individualTaxpayerIdentificationNumber"
            label={t("clients.form.IndividualTaxpayerIndentificationNumber")}
            variant="outlined"
            value={individualTaxpayerIdentificationNumber}
            onChange={(e) =>
              setIndividualTaxpayerIdentificationNumber(e.target.value)
            }
            sx={{
              gridColumn: isNonMobile ? "span 3" : "span 6",
              backgroundColor: colors.primary[100],
            }}
          />
*/}
          <TextField
            id="otherInformations"
            size="small"
            label="Outras Informações"
            variant="outlined"
            value={otherInformations}
            onChange={(e) => setOtherInformations(e.target.value)}
            multiline
            sx={{
              gridColumn: "span 6",
              backgroundColor: colors.primary[100],
            }}
          />

          {!isNonMobile && (
            <Divider variant="middle" sx={{ gridColumn: "span 6" }} />
          )}
          <Box sx={{ gridColumn: isNonMobile ? "span 4" : "span 2" }} />
          <Button
            type="submit"
            disabled={!name || !phoneNumber}
            color="secondary"
            variant="contained"
            height="100%"
            onClick={handleSubmit}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          >
            <Typography fontWeight="bold">
              {t("clients.form.saveButton")}
            </Typography>
          </Button>
          <ColorButton
            variant="contained"
            height="100%"
            onClick={handleClose}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          >
            {" "}
            <Typography fontWeight="bold">
              {t("clients.form.cancelButton")}
            </Typography>
          </ColorButton>
        </Box>
      </Box>
    </Box>
  );
};
export default NewClient;
