import * as React from "react";

import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import InfoIcon from "@mui/icons-material/Info";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { blue, purple, lightGreen } from "@mui/material/colors";

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: "left",

  "&.Today": {
    color: lightGreen[800],
    border: `1px solid ${lightGreen[900]}`,
  },
  "&.Yesterday": {
    color: blue[700],
    border: `1px solid ${blue[900]}`,
  },
  "&.ThisWeek": {
    color: purple[700],
    border: `1px solid ${purple[900]}`,
  },
  "&.ThisMonth": {
    color: "black",
    border: `1px solid black`,
  },
  "&.ThisYear": {
    color: (theme.vars || theme).palette.warning.dark,
    border: `1px solid ${(theme.vars || theme).palette.warning.main}`,
  },
}));

const Status = React.memo((props) => {
  const { date } = props;
  const { t } = useTranslation();

  let icon = null;
  let label = null;
  let className = null;

  if (dayjs(date).format("YY-MM-DD") === dayjs().format("YY-MM-DD")) {
    className = "Today";
    label = t("transactions.table.todayLabel");
    icon = <NewReleasesIcon className="icon" />;
  } else if (
    dayjs(date).format("YY-MM-DD") ===
    dayjs().subtract(1, "day").format("YY-MM-DD")
  ) {
    className = "Yesterday";
    label = t("transactions.table.yesterdayLabel");
    icon = <InfoIcon className="icon" />;
  } else if (dayjs(date) > dayjs().subtract(7, "day")) {
    className = "ThisWeek";
    label = t("transactions.table.thisWeekLabel");
    icon = <AutorenewIcon className="icon" />;
  } else if (dayjs(date) > dayjs().subtract(1, "month")) {
    className = "ThisMonth";
    label = t("transactions.table.thisMonthLabel");
    icon = <EventRepeatIcon className="icon" />;
  } else if (dayjs(date) >= dayjs().subtract(1, "year")) {
    className = t("transactions.table.thisYearLabel");
    label = `${dayjs().diff(date, "day")} Days ago`;
    icon = <EventRepeatIcon className="icon" />;
  } else {
    className = t("transactions.table.thisYearLabel");
    label = "More than One Year ago";
    icon = <EventRepeatIcon className="icon" />;
  }

  return (
    <StyledChip
      className={className}
      size="small"
      label={label}
      variant="outlined"
    />
  );
});

export function renderStatus(params) {
  if (params.value == null) {
    return "";
  }

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
    >
      <Status date={params.value} />
      <Typography>{params.value}</Typography>
    </Box>
  );
}
