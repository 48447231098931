import Sidebar from "./scenes/global/Sidebar";

const Layout = ({ children, showSidebar = true }) => {
  return (
    <div className="app">
      {showSidebar && <Sidebar isSidebar={true} />}
      <main className="content">{children}</main>
    </div>
  );
};

export default Layout;
