import { useState } from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  TextField,
  useTheme,
  Button,
  Typography,
  useMediaQuery,
  Autocomplete,
  Paper,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import useAxiosPrivate from "../../api/useAxiosPrivate";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import NewBankInformationDialog from "../../components/NewBankInformationDialog";

const NEW_RECIPIENT_URL = "/recipients";

const NewRecipient = (props) => {
  const {
    handleClose,
    clients,
    recipients,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
    clientInfo,
  } = props;
  const clientRecipientsInicial = clientInfo
    ? recipients.filter((recipient) => {
        return recipient.clientId === clientInfo.id;
      })
    : {};

  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [client, setClient] = useState(clientInfo || null);
  const [clientRecipients, setClientRecipients] = useState(
    clientRecipientsInicial
  );
  const [recipient, setRecipient] = useState({});
  // const [openNewBankInformation, setOpenNewBankInformation] = useState(false);

  const [name, setName] = useState("");
  const [otherInformations, setOtherInformations] = useState("");
  //const [identificationDocument, setIdentificationDocument] = useState("");

  const handleClientSelect = (client) => {
    setClient(client);
    setClientRecipients(
      recipients.filter((recipient) => {
        return recipient.clientId === client.id;
      })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !client) {
      setAlertMessage("Verfifique os campos obrigatórios");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    try {
      const response = await axiosPrivate.post(
        NEW_RECIPIENT_URL,
        JSON.stringify({
          clientId: client.id,
          name,
          otherInformations,
          //identificationDocument,
        }),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setAlertMessage(`Beneficiário ${response.data.name} criado!`);
        setSeverity("success");
        setOpenAlert(true);
        //setRecipient(response.data);
        //setOpenNewBankInformation(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro do Beneficiário");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Header
        title="NOVO BENEFICIÁRIO"
        subtitle="Cadastre um novo Beneficiário"
        button={false}
        color="#E35A01"
      />

      <Box
        borderRadius="10px"
        marginTop="20px"
        padding="20px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 4" }}
        >
          Cliente
        </Typography>

        <Box sx={{ gridColumn: "span 4" }} />

        <Autocomplete
          fullWidth
          value={client}
          onChange={(event, newCliente) => {
            handleClientSelect(newCliente);
          }}
          disableClearable
          variant="outlined"
          id="nome-cliente"
          options={clients}
          getOptionLabel={(option) => `${option.name}: (${option.phoneNumber})`}
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 4",
            backgroundColor: colors.primary[100],
          }}
          renderInput={(params) => (
            <TextField {...params} label="Nome do Cliente" />
          )}
        />
      </Box>
      {client && (
        <Box
          borderRadius="10px"
          padding="20px"
          marginTop="20px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gap="15px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <Box sx={{ gridColumn: "span 4" }}>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ gridColumn: "span 4" }}
            >
              Beneficiários já Cadastrados
            </Typography>
          </Box>

          <TableContainer
            component={Paper}
            sx={{ gridColumn: "span 4", maxHeight: 200 }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>Nome</b>
                  </TableCell>
                  {/* <TableCell align="left">
                    <b>Documento</b>
                  </TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {clientRecipients.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.name}</TableCell>
                    {/*<TableCell>{row.identificationDocument}</TableCell>*/}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* PARTE DO BENEFICIÁRO */}
      {client && (
        <Box
          borderRadius="10px"
          padding="20px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap="15px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            Novo Beneficiário
          </Typography>
          <Box sx={{ gridColumn: "span 4" }} visibility="hidden" />
          <TextField
            required
            size="small"
            fullWidth
            value={name}
            variant="outlined"
            type="text"
            label="Nome"
            sx={{
              backgroundColor: colors.primary[100],
              gridColumn: "span 4",
            }}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            id="otherInformations"
            size="small"
            label="Outras Informações"
            variant="outlined"
            value={otherInformations}
            onChange={(e) => setOtherInformations(e.target.value)}
            multiline
            sx={{
              gridColumn: "span 4",
              backgroundColor: colors.primary[100],
            }}
          />
        </Box>
      )}

      {client && (
        <Box
          borderRadius="10px"
          padding="10px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gap="5px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <Box sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }} />
          <Button
            disabled={!client || !name}
            type="submit"
            color="secondary"
            variant="contained"
            height="100%"
            onClick={handleSubmit}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          >
            <Typography fontWeight="bold">Registrar Beneficiário</Typography>
          </Button>
          <ColorButton
            variant="contained"
            height="100%"
            onClick={handleClose}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          >
            Cancelar
          </ColorButton>
        </Box>
      )}
    </Box>
  );
};
export default NewRecipient;
