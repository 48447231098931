import {
  Box,
  useTheme,
  Button,
  LinearProgress,
  Typography,
} from "@mui/material";
import { tokens } from "../../../theme";
import clsx from "clsx";
import { useState, useEffect } from "react";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CustomNoRowsOverlay from "../../../components/NoRowsOverlay";
import NewStoreProviderDialog from "../../../components/NewStoreProviderDialog";
import EditStoreProviderDialog from "../../../components/EditStoreProviderDialog";
import DeleteStoreProviderDialog from "../../../components/DeleteStoreProviderDialog";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Toast from "../../../components/Snackbar";
import { styled } from "@mui/material/styles";

import useAxiosPrivate from "../../../api/useAxiosPrivate";

const Providers = () => {
  const axiosPrivate = useAxiosPrivate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [loadingData, setLoadingData] = useState(true);
  const [storeProviders, setStoreProviders] = useState([]);
  const [providers, setProviders] = useState([]);
  const [stores, setStores] = useState([]);

  const [openNewStoreProviderDialog, setOpenNewStoreProviderDialog] =
    useState(false);
  const [openEditStoreProviderDialog, setOpenEditStoreProviderDialog] =
    useState(false);
  const [openDeleteStoreProviderDialog, setOpenDeleteStoreProviderDialog] =
    useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [provider_info, setProvider_info] = useState({});

  useEffect(() => {
    const getStoreProviders = async () => {
      try {
        const response = await axiosPrivate.get("/storeProvider/storeChain");
        setStoreProviders(response.data);
        setLoadingData(false);
      } catch (err) {}
    };
    getStoreProviders();
  }, [
    openDeleteStoreProviderDialog,
    openEditStoreProviderDialog,
    openNewStoreProviderDialog,
  ]);

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await axiosPrivate.get("/stores");
        setStores(response.data);
      } catch (err) {}
    };

    getStores();
  }, []);

  useEffect(() => {
    const getProviders = async () => {
      try {
        const response = await axiosPrivate.get("/providers");
        setProviders(response.data);
      } catch (err) {}
    };

    getProviders();
  }, []);

  const newStoreProvider = () => {
    setOpenNewStoreProviderDialog(true);
  };

  const handleClose = () => {
    setOpenNewStoreProviderDialog(false);
    setOpenEditStoreProviderDialog(false);
    setOpenDeleteStoreProviderDialog(false);
  };

  const handleEditClick = (row) => {
    setProvider_info(row);
    setOpenEditStoreProviderDialog(true);
  };

  const handleDeleteClick = (id) => () => {
    setProvider_info(
      storeProviders.find((storeProvider) => storeProvider.id === id)
    );
    setOpenDeleteStoreProviderDialog(true);
  };

  function numberWithCommas(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
    return x;
  }

  const columns = [
    {
      field: "active",
      headerName: "Ativo",
      type: "boolean",
      cellClassName: (params) => {
        if (params.value == null) {
          return "";
        }

        return clsx("super-app", {
          negative: params.value === false,
          positive: params.value === true,
        });
      },
    },
    {
      field: "Provider",
      flex: 1,
      headerName: "Provedora",
      valueGetter: (params) => {
        return params.row.Provider.name;
      },
    },
    ,
    {
      field: "Store",
      headerName: "Loja",
      valueGetter: (params) => {
        return params.row.Store.name;
      },
    },
    {
      field: "customDailyLimit",
      headerName: "Limite 7 Dias (USD)",
      flex: 1,
      type: "number",
      valueFormatter: (params) => {
        return `${numberWithCommas(params.value.toFixed(2))}`;
      },
    },
    {
      field: "customMonthlyLimit",
      headerName: "Limite 30 Dias (USD)",
      flex: 1,
      type: "number",
      valueFormatter: (params) => {
        return `${numberWithCommas(params.value.toFixed(2))}`;
      },
    },
    {
      field: "customAnnualLimit",
      headerName: "Limite 1 Ano (USD)",
      flex: 1,
      type: "number",
      valueFormatter: (params) => {
        return `${numberWithCommas(params.value.toFixed(2))}`;
      },
    },
    /*  {
      field: "fixedFee",
      headerName: "Taxa Padrão USD",
      flex: 1,
      type: "number",
      valueFormatter: (params) => {
        return `${numberWithCommas(params.value.toFixed(2))}`;
      },
    },

    {
      headerName: "Spread BRL",
      flex: 1,
      type: "number",
      valueGetter: (params) => {
        return `${(
          params.row.quotationProvider - params.row.quotationClient
        ).toFixed(2)}`;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Opções",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },*/
  ];

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#621B74",
    "&:hover": {
      backgroundColor: "#814990",
    },
  }));

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 1,
          display: "flex",
          gap: "10px",
        }}
      >
        <GridToolbarQuickFilter
          placeholder="Busque por Provedora ou Loja"
          variant="outlined"
          style={{ flex: 0.5 }}
        />
        <ColorButton
          variant="contained"
          onClick={newStoreProvider}
          sx={{ gridColumn: "span 2" }}
        >
          <AddCircleOutlinedIcon sx={{ mr: "10px" }} />
          <Typography fontWeight="bold">NOVO</Typography>
        </ColorButton>
      </Box>
    );
  }
  return (
    <Box>
      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: "#0c1c3c",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#621B74",
            borderBottom: "none",
            color: "white",
            maxHeight: "168px !important",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.grey[100],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "#fff",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !Important`,
          },
          "& .super-app-theme--cell": {
            backgroundColor: "rgba(224, 183, 60, 0.55)",
            color: "#1a3e72",
            fontWeight: "600",
          },
          "& .super-app.positive": {
            backgroundColor: "rgba(157, 255, 118, 0.49)",
            color: "#1a3e72",
            fontWeight: "600",
          },
          "& .super-app.negative": {
            backgroundColor: "#d47483",
            color: "#1a3e72",
            fontWeight: "600",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal",
          },
          "& .MuiDataGrid-columnHeader": {
            height: "unset !important",
          },
        }}
      >
        <DataGrid
          loading={loadingData}
          getRowId={(row) => row.id}
          rows={storeProviders}
          columns={columns}
          slots={{
            toolbar: QuickSearchToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
            loadingOverlay: LinearProgress,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          onRowClick={(row) => handleEditClick(row.row)}
          disableColumnMenu
          initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
          pageSizeOptions={[25, 50, 100]}
        />
      </Box>
      <Toast
        open={openAlert}
        alertMessage={alertMessage}
        setOpen={setOpenAlert}
        severity={severity}
      />
      <NewStoreProviderDialog
        openNewStoreProviderDialog={openNewStoreProviderDialog}
        handleClose={handleClose}
        providers={providers}
        stores={stores}
        storeProviders={storeProviders}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
      <EditStoreProviderDialog
        openEditStoreProviderDialog={openEditStoreProviderDialog}
        handleClose={handleClose}
        provider_info={provider_info}
        providers={providers}
        stores={stores}
        storeProviders={storeProviders}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
      <DeleteStoreProviderDialog
        openDeleteStoreProviderDialog={openDeleteStoreProviderDialog}
        handleClose={handleClose}
        provider_info={provider_info}
        providers={providers}
        stores={stores}
        storeProviders={storeProviders}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
    </Box>
  );
};
export default Providers;
