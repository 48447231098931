import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Providers from "../admin/providers/index";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import PageNotFound from "../../404";
import useAxiosPrivate from "../../api/useAxiosPrivate";

export default function AdminTab() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState("1");
  const [show, setShow] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const response = await axiosPrivate.get("/checkAdmin");
        setShow(true);
      } catch (err) {}
    };

    checkAdmin();
  }, []);

  return (
    <>
      {show ? (
        <Box m="8px">
          <Header
            title="Administrador"
            subtitle="Ferramentas de Adminstração do Sistema"
          />
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: colors.grey[300],
              }}
            >
              <TabList
                scrollButtons="auto"
                allowScrollButtonsMobile
                indicatorColor="secondary"
                textColor="inherit"
                onChange={handleChange}
              >
                <Tab label="Provedoras" value="1" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ py: "4px", px: "0px" }}>
              <Providers />
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <PageNotFound />
      )}{" "}
    </>
  );
}
