import { useState, useEffect } from "react";
import Image from "../register/Design-sem-nome.jpg";
import msb from "../register/logo.png";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import useAuth from "../../hooks/useAuth.js";
import { Box, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Toast from "../../components/Snackbar";

import axios from "../../api/axios.js";
const LOGIN_URL = "/login";

const Login = () => {
  const { setAuth, persist, setPersist } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = email;
    const v2 = password;
    if (!v1 || !v2) {
      setAlertMessage("Verifique os Campos Obrigatórios");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({
          email,
          password,
        }),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      //console.log(response?.accessToken);
      const accessToken = response?.data.accessToken;
      const stores = response?.data?.authorizedStores;
      setAuth({ user: email, accessToken, stores });
      setAlertMessage("Welcome! " + email + " !");
      setSeverity("success");
      setOpenAlert(true);

      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setAlertMessage("O Login falhou");
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage("Verifique os campos obrigatórios");
        setSeverity("warning");
        setOpenAlert(true);
      } else if (err.response?.status === 403) {
        setAlertMessage(
          "Sua assinatura está desatualizada, entre em contato com o nosso suporte"
        );
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("O Login falhou");
        setSeverity("error");
        setOpenAlert(true);
      }
      //errRef.current.focus();
    }
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <>
      <Toast
        open={openAlert}
        setOpen={setOpenAlert}
        alertMessage={alertMessage}
        severity={severity}
      />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${Image})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          component={Paper}
          elevation={6}
          square
          backgroundColor={colors.secondary}
          width="50vh"
          height="100vh"
          position="absolute"
          right={0}
        >
          <Box
            sx={{
              my: 8,
              mx: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {<img src={msb} height="70px" alt="logo" />}

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
              //textAlign={"center"}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                autoComplete="off"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    onChange={togglePersist}
                    checked={persist}
                    sx={{
                      color: colors.primary[700],
                      "&.Mui-checked": {
                        color: colors.primary[500],
                      },
                      "&:hover": { color: colors.primary[500] },
                    }}
                  />
                }
                label="Confiar neste dispositivo."
              />
              <Grid item>
                <Link
                  href="/reset-password"
                  variant="body1"
                  color="rgb(12,28,60)"
                  sx={[
                    {
                      "&:visited": {
                        color: "#0c64fb",
                      },
                    },
                  ]}
                >
                  {"Esqueceu a senha?"}
                </Link>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  //alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Button
                  disabled={!email || !password ? true : false}
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: colors.primary[700],
                    fontStyle: { color: "white", fontWeight: "bold" },
                    "&:hover": { backgroundColor: colors.primary[500] },
                  }}

                  //asascolor={colors.blueAccent[600]}
                >
                  Entrar
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Box>
                  <Typography>Ainda não é cadastrado? </Typography>
                  <Typography fontWeight={"bold"}>
                    Faça um teste grátis
                  </Typography>
                </Box>
                <Button
                  type="link"
                  href="https://msbmanager.us/pt/planos/"
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: colors.primary[700],
                    fontStyle: { color: "white", fontWeight: "bold" },
                    "&:hover": { backgroundColor: colors.primary[500] },
                  }}
                >
                  CADASTRE-SE
                </Button>
              </Box>
              <Grid item>
                <br />
                <p>
                  Ao clicar em Entrar, você está de acordo com os nossos{" "}
                  <a
                    style={{
                      color: "rgb(12,28,60)",
                      "&:visited": {
                        color: "#0c64fb",
                      },
                    }}
                    href="https://msbmanager.us/pt/termos-e-condicoes-de-uso/"
                    target="_blank"
                  >
                    Termos de Uso
                  </a>{" "}
                  e{" "}
                  <a
                    style={{ color: "rgb(12,28,60)" }}
                    href="https://msbmanager.us/pt/politicas-de-privacidade/"
                    target="_blank"
                  >
                    Política de Privacidade
                  </a>
                  .
                </p>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
