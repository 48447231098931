import { useState } from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  useTheme,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import useAxiosPrivate from "../../../api/useAxiosPrivate";

const DELETE_STORE_PROVIDER_URL = "/storeProvider";

const DeleteStoreProvider = (props) => {
  const {
    handleCloseDeleteStoreProviderDialog,
    handleCloseEditStoreProviderDialog,
    provider_info,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;

  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack

    try {
      const response = await axiosPrivate.delete(
        `${DELETE_STORE_PROVIDER_URL}/${provider_info.id}`,
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 204) {
        setAlertMessage(
          `Provedora ${provider_info.Provider.name} removida da Loja: "${provider_info.Store.name}"`
        );
        setSeverity("success");
        setOpenAlert(true);
        handleCloseDeleteStoreProviderDialog();
        handleCloseEditStoreProviderDialog();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha na remoção da Provedora");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Header
        color="#621B74"
        title="! REMOVER PROVEDORA"
        subtitle="ATENÇÃO: Remover esta provedora também irá excluir todas as remessas feitas através dela"
        button={false}
      />
      <Box
        sx={{
          borderRadius: 3,
          autoComplete: "off",
          padding: 3,
          gap: 2,
          bgcolor: colors.grey[100],
          display: "grid",
          gridTemplateColumns: "repeat(8, 1fr)",
        }}
      >
        {isNonMobile && <Box sx={{ gridColumn: "span 4" }} />}

        <Button
          type="submit"
          color="secondary"
          variant="contained"
          height="100%"
          onClick={handleSubmit}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
        >
          <Typography fontWeight="bold">Remover Provedora</Typography>
        </Button>
        <ColorButton
          variant="contained"
          height="100%"
          onClick={handleCloseDeleteStoreProviderDialog}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
        >
          <Typography fontWeight="bold">Cancelar</Typography>
        </ColorButton>
      </Box>
    </Box>
  );
};
export default DeleteStoreProvider;
