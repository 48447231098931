import * as XLSX from "xlsx";
import dayjs from "dayjs";
import countries from "../../utils/countryList/countries2.json";

const readExcelFile = (data, worksheetName) => {
  try {
    const workbook = XLSX.read(data, { type: "buffer" });
    return workbook.Sheets[worksheetName];
  } catch (err) {}
};

const handleFileAnalysisLP = (file, callbackError, callbackSuccess) => {
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  var reader = new FileReader();

  reader.onload = (e) => {
    var data = e.target.result;
    const worksheet = readExcelFile(data, "Transactions Ledger");

    if (!worksheet) {
      callbackError("error", "Planilha não encontrada no Arquivo");
      callbackSuccess([]);
    } else {
      var range = XLSX.utils.decode_range(worksheet["!ref"]);
      range.s.r = 2;
      worksheet["!ref"] = XLSX.utils.encode_range(range);
      const result = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      const load = [];
      for (const transaction of result) {
        if (!dayjs(transaction["Date & Time"]).isValid()) {
          callbackError("error", "Planilha com datas erradas");
          callbackSuccess([]);
        } else {
          const element = {
            dateTransaction: dayjs(transaction["Date & Time"]).format(
              "YYYY-MM-DD"
            ),
            destinyCountry: transaction.Currency,
            sender: transaction["Sender Name"],
            senderPhone: transaction["Sender Phone"],
            recipient: transaction["Receiver Name"],
            amountSent: USDollar.format(Number(transaction["USD Sent"])),
            fee: USDollar.format(Number(transaction.Fee)),
            amountCollected: USDollar.format(
              Number(transaction.Fee) + Number(transaction["USD Sent"])
            ),
            paymentMethod: "NAO INFORMADO",
          };
          if (Number(element.amountSent.replace(",", "")) > 0)
            load.push(element);
        }
      }

      callbackSuccess(load);
    }
  };
  reader.readAsArrayBuffer(file);
};

const handleFileAnalysisUNO = (file, callbackError, callbackSuccess) => {
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  var reader = new FileReader();

  reader.onload = (e) => {
    var data = e.target.result;
    const worksheet = readExcelFile(data, "agentStatements_netAll");
    if (!worksheet) {
      callbackError("error", "Planilha não encontrada no Arquivo");
      callbackSuccess([]);
    } else {
      const result = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      //console.log(result);
      const load = [];
      for (const transaction of result) {
        if (
          Object.keys(transaction).length >= 15 &&
          dayjs(transaction["Agent Statements"]).isValid() &&
          transaction["__EMPTY_19"]
        ) {
          const element = {
            dateTransaction: dayjs(transaction["Agent Statements"]).format(
              "YYYY-MM-DD"
            ),
            destinyCountry: "NÃO INFORMADO",
            sender: transaction["__EMPTY_19"],
            senderPhone: "NÃO INFORMADO",
            recipient: "NÃO INFORMADO",
            amountSent: USDollar.format(
              Number(transaction["__EMPTY_22"].replace(",", ""))
            ),
            fee: USDollar.format(
              Number(transaction["__EMPTY_23"].replace(",", ""))
            ),
            amountCollected: USDollar.format(
              Number(transaction["__EMPTY_22"].replace(",", "")) +
                Number(transaction["__EMPTY_23"].replace(",", ""))
            ),
            /*profit: USDollar.format(
              Number(transaction["__EMPTY_32"].replace(",", ""))
            ),*/
            paymentMethod: transaction["__EMPTY_8"],
          };
          if (Number(element.amountSent.replace(",", "")) > 0)
            load.push(element);
        }
      }
      callbackSuccess(load);
    }
  };
  reader.readAsArrayBuffer(file);
};

const handleFileAnalysisWU = (file, callbackError, callbackSuccess) => {
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });
  //const countries = countriesJSON;

  var reader = new FileReader();

  reader.onload = (e) => {
    var data = e.target.result;
    var allTextLines = data.split("\n");
    if (allTextLines.length < 4) {
      callbackError("error", "Informações não encontradas no arquivo");
      callbackSuccess([]);
    } else {
      var headers = allTextLines[3].split(",");
      var lines = [];

      if (headers.length !== 26) {
        callbackError("error", "Informações não encontradas no arquivo");
        callbackSuccess([]);
      } else {
        for (var i = 4; i < allTextLines.length; i++) {
          var data = allTextLines[i].split(",");
          if (data.length == headers.length) {
            var tarr = {};
            for (var j = 0; j < headers.length; j++) {
              tarr[headers[j].replace(/['"]+/g, "")] = data[j].replace(
                /['"]+/g,
                ""
              );
            }
            lines.push(tarr);
          }
        }
        const load = [];
        for (const transaction of lines) {
          if (
            dayjs(transaction["Date"], "DD/MM/YYYY", true).isValid() &&
            transaction["Destination Country Code"] !== "US"
          ) {
            const element = {
              dateTransaction: dayjs(
                `${transaction["Date"]}-${transaction["Time"]}`,
                "DD/MM/YYYY-h:mm A"
              ).format("YYYY-MM-DD HH:mm"),
              destinyCountry:
                countries.filter(
                  (country) =>
                    country.iso_code === transaction["Destination Country Code"]
                )[0]?.country || "NÃO INFORMADO",
              sender: transaction["Sender"],
              senderPhone: "NÃO INFORMADO",
              recipient: transaction["Receiver Name"],
              amountSent: USDollar.format(
                Number(transaction["Amount Sent"].replace(",", ""))
              ),
              fee: USDollar.format(
                Number(transaction["Charge"].replace(",", ""))
              ),
              amountCollected: USDollar.format(
                Number(transaction["Amount Sent"].replace(",", "")) +
                  Number(transaction["Charge"].replace(",", ""))
              ),
              //profit: "O",
              paymentMethod: transaction["Payment Type"],
            };
            if (Number(element.amountSent.replace(",", "")) > 0)
              load.push(element);
          }
        }
        callbackSuccess(load);
      }
    }
  };
  reader.readAsText(file);
};

const handleFileAnalysisRIA = (file, callbackError, callbackSuccess) => {
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  var reader = new FileReader();

  reader.onload = (e) => {
    var data = e.target.result;
    var allTextLines = data
      .split("\n")
      .filter((line) => line.slice(1, 3) === "US");
    var headers = [
      "Order Number",
      "Order Date",
      "Customer",
      "Seq. ID",
      "Currency From",
      "Amount Sent",
      "Fee",
      "Tax",
      "Total",
      "Comm",
    ];

    var lines = [];

    if (allTextLines.length < 1) {
      callbackError("error", "Dados não encontrados no arquivo");
      callbackSuccess([]);
    } else if (allTextLines[0].split(",").length !== headers.length) {
      callbackError("error", "Dados não encontrados no arquivo");
      callbackSuccess([]);
    } else {
      for (var i = 0; i < allTextLines.length; i++) {
        var data = allTextLines[i].match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);
        if (data.length == headers.length) {
          var tarr = {};
          for (var j = 0; j < headers.length; j++) {
            tarr[headers[j].replace(/['"]+/g, "")] = data[j].replace(
              /['"]+/g,
              ""
            );
          }
          lines.push(tarr);
        }
      }
      const load = [];

      for (const transaction of lines) {
        if (
          dayjs(transaction["Order Date"], "M/D/YYYY h:mm:ss A", true).isValid()
        ) {
          const element = {
            dateTransaction: dayjs(transaction["Order Date"]).format(
              "YYYY-MM-DD HH:mm"
            ),
            destinyCountry: "NÃO INFORMADO",
            sender: transaction["Customer"],
            senderPhone: "NÃO INFORMADO",
            recipient: "NÃO INFORMADO",
            amountSent: USDollar.format(
              Number(transaction["Amount Sent"].replace(",", ""))
            ),
            fee: USDollar.format(Number(transaction["Fee"].replace(",", ""))),
            amountCollected: USDollar.format(
              Number(transaction["Amount Sent"].replace(",", "")) +
                Number(transaction["Fee"].replace(",", ""))
            ),
            //profit: 0,
            paymentMethod: "NÃO INFORMADO",
          };
          if (Number(element.amountSent.replace(",", "")) > 0)
            load.push(element);
        }
      }
      callbackSuccess(load);
    }
  };
  reader.readAsText(file);
};

const handleFileAnalysisGolden = (file, callbackError, callbackSuccess) => {
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });
  //const countries = countriesJSON;
  var reader = new FileReader();

  reader.onload = (e) => {
    var data = e.target.result;
    var allTextLines = data.split("\n");
    var headers = [
      "Receipt",
      "Passwor",
      "Creation_date",
      "Statuss",
      "Payer",
      "Agency_Name",
      "Cashier",
      "Sender",
      "Receiver",
      "Amount_sent",
      "Fees",
      "Total",
      "Amount_receive",
      "Currency",
      "OriginPaymentMethod",
      "Textbox70",
      "Textbox71",
      "Textbox72",
      "Textbox73",
    ];
    var lines = [];

    if (allTextLines.length < 1) {
      callbackError("error", "Informações não encontradas no arquivo");
      callbackSuccess([]);
    } else if (allTextLines[0].split(",").length !== headers.length) {
      callbackError("error", "Dados não encontrados no arquivo");
      callbackSuccess([]);
    } else {
      for (var i = 1; i < allTextLines.length; i++) {
        var data = allTextLines[i].split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/g);
        if (data.length == headers.length) {
          var tarr = {};
          for (var j = 0; j < headers.length; j++) {
            tarr[headers[j]] = data[j].replace(/['"]+/g, "");
          }
          lines.push(tarr);
        }
      }
      const load = [];

      for (const transaction of lines) {
        if (
          dayjs(
            transaction["Creation_date"],
            "M/D/YYYY h:mm:ss A",
            true
          ).isValid() &&
          transaction["Statuss"] === "PAID"
        ) {
          const element = {
            dateTransaction: dayjs(transaction["Creation_date"]).format(
              "YYYY-MM-DD HH:mm"
            ),
            destinyCountry:
              countries.filter(
                (country) => country.iso_code == transaction["Currency"]
              ).length === 1
                ? countries.filter(
                    (country) => country.iso_code === transaction["Currency"]
                  )[0].country
                : `Zona do ${transaction.Currency}`,
            sender: transaction["Sender"],
            senderPhone: "NÃO INFORMADO",
            recipient: transaction["Receiver"],
            amountSent: USDollar.format(
              Number(transaction["Amount_sent"].replace("$", ""))
            ),
            fee: USDollar.format(Number(transaction["Fees"].replace("$", ""))),
            //profit: 0,
            amountCollected: USDollar.format(
              Number(transaction["Amount_sent"].replace("$", "")) +
                Number(transaction["Fees"].replace("$", ""))
            ),
            paymentMethod: transaction["OriginPaymentMethod"],
          };
          if (Number(element.amountSent.replace(",", "")) > 0)
            load.push(element);
        }
      }
      callbackSuccess(load);
    }
  };
  reader.readAsText(file);
};

export {
  handleFileAnalysisLP,
  handleFileAnalysisUNO,
  handleFileAnalysisWU,
  handleFileAnalysisRIA,
  handleFileAnalysisGolden,
};
