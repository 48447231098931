import { Box, useTheme, Button, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import { useState, useEffect } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../../components/NoRowsOverlay";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { NumericFormat } from "react-number-format";
import InputAdornment from "@mui/material/InputAdornment";
import * as XLSX from "xlsx";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import useAxiosPrivate from "../../../api/useAxiosPrivate";

import { Autocomplete, TextField } from "@mui/material";

import { styled } from "@mui/material/styles";

const TransactionGeneralReport = () => {
  const axiosPrivate = useAxiosPrivate();

  const [show, setShow] = useState(false);

  const isNonMobile = useMediaQuery("(min-width:900px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [loadingData, setLoadingData] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [providers, setProviders] = useState([]);
  const [storeProviders, setStoreProviders] = useState([]);
  const [stores, setStores] = useState([]);
  const [clients, setClients] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [client, setClient] = useState([]);
  const [recipient, setRecipient] = useState([]);

  const [minimumTransactionValue, setMinimumTransactionValue] = useState(0);
  const [maximumTransactionValue, setMaximumTransactionValue] = useState(0);
  const [provider, setProvider] = useState([]);
  const [store, setStore] = useState([]);

  const [filterClients, setFilterClients] = useState([]);
  const [filterRecipients, setFilterRecipients] = useState([]);
  const [filterProviders, setFilterProviders] = useState([]);
  const [filterStores, setFilterStores] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState(
    dayjs().startOf("year")
  );
  const [filterEndDate, setFilterEndDate] = useState(dayjs().endOf("year"));

  const [transactionsFiltered, setTransactionsFiltered] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get("/users/storeChain");
        setShow(true);
      } catch (err) {}
    };

    getUsers();
  }, []);

  useEffect(() => {
    const getProviders = async () => {
      try {
        const response = await axiosPrivate.get("/storeProvider/storeChain");
        setProviders(
          response.data
            .map((item) => item.Provider)
            .filter(
              (obj1, i, arr) =>
                arr.findIndex((obj2) => obj2.id === obj1.id) === i
            )
        );
        setStoreProviders(response.data);
      } catch (err) {}
    };
    getProviders();
  }, []);

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await axiosPrivate.get("/stores");
        setStores(response.data);
      } catch (err) {}
    };

    getStores();
  }, []);

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await axiosPrivate.get("/clients");
        setClients(response.data);
      } catch (err) {}
    };

    getClients();
  }, []);

  useEffect(() => {
    const getRecipients = async () => {
      try {
        const response = await axiosPrivate.get("/recipients");
        setRecipients(response.data);
      } catch (err) {}
    };

    getRecipients();
  }, []);

  const getTransactionsFiltered = async () => {
    const params = {
      clients: filterClients,
      recipients: filterRecipients,
      stores: filterStores,
      storeProviders: filterProviders,
      startDate: filterStartDate.format("YYYY-MM-DD"),
      endDate: filterEndDate.add(1, "day").format("YYYY-MM-DD"),
      minValue: minimumTransactionValue || 0,
      maxValue: maximumTransactionValue || 1000000,
    };

    try {
      const response = await axiosPrivate.get("/reports/transactions", {
        params,
      });
      setTransactionsFiltered(response.data);
      setLoadingData(false);
    } catch (err) {}
  };

  const columns = [
    {
      field: "client",
      headerName: "Cliente",
      flex: 1,
      valueGetter: (params) => {
        return params.row.client ? params.row.client.name : "Desconhecido";
      },
    },
    {
      field: "recipient",
      headerName: "Beneficiário",
      flex: 1,
      valueGetter: (params) => {
        return params.row.recipient
          ? params.row.recipient.name
          : "Desconhecido";
      },
    },

    {
      field: "amountCollected",
      headerName: "Valor",
      flex: 1,
      type: "number",
      valueFormatter: (params) => {
        return `US$ ${params.value.toFixed(2)}`;
      },
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "dateTransaction",
      headerName: "Data",
      flex: 1,
      valueGetter: (params) => {
        return dayjs(params.row.dateTransaction).format("YYYY-MM-DD");
      },
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "provider",
      headerName: "Provedora",
      flex: 1,
      valueGetter: (params) => {
        return params.row.storeProvider
          ? params.row.storeProvider.Provider.name
          : "Desconhecido";
      },
    },
    {
      field: "store",
      headerName: "Loja",
      valueGetter: (params) => {
        return params.row.store ? params.row.store.name : "Desconhecido";
      },
    },
  ];

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#62929E",
    "&:hover": {
      backgroundColor: "#546A7B",
    },
  }));

  const applyFilters = () => {
    setLoadingData(true);
    getTransactionsFiltered();
  };

  const clearFilters = () => {
    setTransactionsFiltered(transactions);
    setClient([]);
    setFilterClients([]);

    setRecipient([]);
    setFilterRecipients([]);

    setFilterStartDate(dayjs().startOf("year"));
    setFilterEndDate(dayjs().endOf("year"));
    setMinimumTransactionValue(0);
    setMaximumTransactionValue(0);

    setProvider([]);
    setFilterProviders([]);

    setStore([]);
    setFilterStores([]);
  };

  const ExportData = (data) => {
    const filename = "MSBManager_report.xlsx";
    const x = data.map((transacion) =>
      (({
        store,
        client,
        recipient,
        storeProvider,
        destinyCountry,
        amountCollected,
        paymentMethod,

        dateTransaction,
      }) => ({
        Loja: store.name,
        Cliente: client.name,
        Beneficiário: recipient.name,
        Provedora: storeProvider.Provider.name,
        "País de Destino": destinyCountry,
        "Valor (USD)": amountCollected.toFixed(2),
        "Forma de Pagamento": paymentMethod,
        "Data da Remessa": dateTransaction.split(" ")[0],
      }))(transacion)
    );
    var ws = XLSX.utils.json_to_sheet(x);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "msb_transactions");
    XLSX.writeFile(wb, filename);
  };

  return (
    <Box>
      <Box>
        {show ? (
          <Box
            height="65vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: "#0c1c3c",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#62929E",
                borderBottom: "none",
                color: "white",
                maxHeight: "168px !important",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.grey[100],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "#62929E",
                color: "white",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !Important`,
              },
              "& .super-app-theme--cell": {
                backgroundColor: "rgba(224, 183, 60, 0.55)",
                color: "#1a3e72",
                fontWeight: "600",
              },
              "& .super-app.positive": {
                backgroundColor: "rgba(157, 255, 118, 0.49)",
                color: "#1a3e72",
                fontWeight: "600",
              },
              "& .super-app.negative": {
                backgroundColor: "#d47483",
                color: "#1a3e72",
                fontWeight: "600",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
              },
              "& .MuiDataGrid-columnHeader": {
                height: "unset !important",
              },
            }}
          >
            <Box
              sx={{
                p: 0.5,
                pb: 1,
                display: "grid",
                gap: "10px",
                gridTemplateColumns: "repeat(6, 1fr)",
              }}
            >
              <Autocomplete
                multiple
                fullWidth
                value={client}
                variant="outlined"
                id="client"
                options={clients}
                getOptionLabel={(option) =>
                  `${option.name}   (${option.phoneNumber})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Clientes"
                    variant="outlined"
                    style={{ flex: 0.5 }}
                  />
                )}
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 6" }}
                onChange={(_, newValue) => {
                  setClient(newValue);
                  if (newValue.length > 0) {
                    setFilterClients(newValue.map((item) => item.id));
                  } else {
                    setFilterClients(clients.map((item) => item.id));
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              <Autocomplete
                multiple
                fullWidth
                value={recipient}
                variant="outlined"
                id="recipent"
                options={recipients}
                getOptionLabel={(option) =>
                  `${option.name} (${option.client.name})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Beneficiários"
                    variant="outlined"
                    style={{ flex: 0.5 }}
                  />
                )}
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 6" }}
                onChange={(_, newValue) => {
                  setRecipient(newValue);
                  if (newValue.length > 0) {
                    setFilterRecipients(newValue.map((item) => item.id));
                  } else {
                    setFilterRecipients(recipients.map((item) => item.id));
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              <DatePicker
                disableFuture
                label="Data de Início (AAAA-MM-DD)"
                value={filterStartDate}
                //defaultValue={}
                onChange={(newValue) =>
                  setFilterStartDate(dayjs(newValue).hour(12).minute(0))
                }
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
                format="YYYY-MM-DD"
              />
              <DatePicker
                label="Data de Fim (AAAA-MM-DD)"
                value={filterEndDate}
                onChange={(newValue) =>
                  setFilterEndDate(dayjs(newValue).hour(12).minute(0))
                }
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
                format="YYYY-MM-DD"
              />
              <Autocomplete
                multiple
                fullWidth
                value={provider}
                variant="outlined"
                id="store-provider"
                options={providers}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Provedora"
                    variant="outlined"
                    style={{ flex: 0.5 }}
                  />
                )}
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 6" }}
                onChange={(_, newValue) => {
                  setFilterProviders(
                    storeProviders
                      .filter((item) =>
                        newValue
                          .map((item) => item.id)
                          .includes(item.providerId)
                      )
                      .map((item) => item.id)
                  );
                  setProvider(newValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              <Autocomplete
                multiple
                fullWidth
                value={store}
                variant="outlined"
                id="store"
                options={stores}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Lojas"
                    variant="outlined"
                    style={{ flex: 0.5 }}
                  />
                )}
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 6" }}
                onChange={(_, newValue) => {
                  setStore(newValue);
                  if (newValue.length > 0) {
                    setFilterStores(newValue.map((item) => item.id));
                  } else {
                    setFilterStores(stores.map((item) => item.id));
                  }
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              <NumericFormat
                decimalScale={2}
                thousandSeparator
                fixedDecimalScale
                value={minimumTransactionValue}
                customInput={TextField}
                id="minimum-value"
                variant="outlined"
                type="text"
                label="Valor Mínimo"
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
                onValueChange={(e) => setMinimumTransactionValue(e.floatValue)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">USD </InputAdornment>
                  ),
                  sx: { "& input": { textAlign: "right" } },
                }}
              />
              <NumericFormat
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                value={maximumTransactionValue}
                customInput={TextField}
                id="maximum-value"
                variant="outlined"
                type="text"
                label="Valor Máximo"
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
                onValueChange={(e) => setMaximumTransactionValue(e.floatValue)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">USD </InputAdornment>
                  ),
                  sx: { "& input": { textAlign: "right" } },
                }}
              />

              <ColorButton
                variant="contained"
                onClick={applyFilters}
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
              >
                <FilterAltOutlinedIcon sx={{ mr: "10px" }} />
                <Typography fontWeight="bold">APLICAR FILTROS</Typography>
              </ColorButton>
              <ColorButton
                variant="contained"
                onClick={clearFilters}
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
              >
                <FilterAltOffOutlinedIcon sx={{ mr: "10px" }} />
                <Typography fontWeight="bold">REMOVER FILTROS</Typography>
              </ColorButton>
              <Box sx={{ gridColumn: isNonMobile ? "span 3" : "span 6" }} />
              <ColorButton
                variant="contained"
                onClick={() => ExportData(transactionsFiltered)}
                sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
              >
                <FileDownloadOutlinedIcon sx={{ mr: "10px" }} />
                <Typography fontWeight="bold">EXPORTAR EXCEL</Typography>
              </ColorButton>
            </Box>
            <DataGrid
              loading={loadingData}
              initialState={{
                sorting: {
                  sortModel: [{ field: "dateTransaction", sort: "desc" }],
                },
                pagination: { paginationModel: { pageSize: 25 } },
              }}
              pageSizeOptions={[25, 50, 100]}
              getRowId={(row) => row.id}
              rows={transactionsFiltered}
              columns={columns}
              slots={{
                //toolbar: QuickSearchToolbar,
                loadingOverlay: LinearProgress,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              disableColumnMenu
            />
          </Box>
        ) : (
          <Box
            sx={{
              borderRadius: 3,
              autoComplete: "off",
              padding: 3,
              gap: 2,
              bgcolor: colors.primary[400],
              display: "grid",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              Não Autorizado
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default TransactionGeneralReport;
