import useAxiosPrivate from "../../api/useAxiosPrivate";
import { Button, useTheme } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useState } from "react";

const UploadButton = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [transactionsCreated, setTransactionsCreated] = useState(0);

  const {
    file,
    store,
    provider,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
    handleClose,
  } = props;

  const findOrCreateClient = async (info) => {
    try {
      const response = await axiosPrivate.post(
        "/clients/findCreate",
        JSON.stringify(info),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );
      return response.data;
    } catch (err) {}
  };

  const findOrCreateRecipient = async (info) => {
    try {
      const response = await axiosPrivate.post(
        "/recipients/findCreate",
        JSON.stringify(info),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );
      return response.data;
    } catch (err) {}
  };

  const findClient = async (file) => {
    let clientErrors = 0;
    let clientCreated = 0;
    let clientFound = 0;

    for (const element of file) {
      if (!element.sender) {
        clientErrors += 1;
      } else {
        const clientInfo = {
          name: element.sender,
        };
        const client = await findOrCreateClient(clientInfo);
        element.clientId = client.client.id;
        client.created ? (clientCreated += 1) : (clientFound += 1);
      }
    }
    return file;
  };

  const findRecipient = async (file) => {
    for (const element of file) {
      if (!element.recipient || !element.clientId) {
      } else {
        const recipientInfo = {
          name: element.recipient,
          clientId: element.clientId,
        };
        const recipient = await findOrCreateRecipient(recipientInfo);
        element.recipientId = recipient.recipient.id;
      }
    }
    return file;
  };

  const createLoad = async (data) => {
    const load = [];
    for (const transaction of data) {
      const remessa = {
        dateTransaction: transaction.dateTransaction,
        storeId: store.id,
        destinyCountry: transaction.destinyCountry,
        storeProviderId: provider.id,
        amountCollected: Number(transaction.amountCollected.replace(",", "")),
        amountSent: Number(transaction.amountSent.replace(",", "")),
        fee: Number(transaction.fee.replace(",", "")),
        profit: 0,
        clientId: transaction.clientId,
        recipientId: transaction.recipientId,
        paymentMethod: transaction.paymentMethod,
        quotationProvider: 0,
        quotationClient: 0,
      };
      load.push(remessa);
    }
    return load;
  };

  const upload = async (file) => {
    setLoading(true);
    let data1 = await findClient(file);
    let data2 = await findRecipient(data1);
    let load = await createLoad(data2);

    for (let i = 0; i < load.length; i += 100) {
      const chunk = load.slice(i, i + 100);
      try {
        const response = await axiosPrivate.post(
          "/transactions/bulkCreate",
          JSON.stringify(chunk),
          {
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            withCredentials: true,
          }
        );

        if (response.status === 201) {
          if (i + 100 >= load.length) {
            setAlertMessage(`${load.length} Transações foram criadas`);
            setSeverity("success");
            setOpenAlert(true);
            setTransactionsCreated(transactionsCreated + response.data.length);
            handleClose();
          }
        }
      } catch (err) {
        if (!err?.response) {
          setAlertMessage(
            "O servidor não está respondendo. Por favor tente novamente mais tarde."
          );
          setSeverity("error");
          setOpenAlert(true);
        } else if (err.response?.status === 400) {
          setAlertMessage(err.response?.data.message || "Erro");
          setSeverity("error");
          setOpenAlert(true);
        } else {
          setAlertMessage("Falha no Registro das Remessas");
          setSeverity("error");
          setOpenAlert(true);
        }
      }
    }
  };

  return (
    <Button
      disabled={loading}
      variant="contained"
      component="label"
      startIcon={<CloudUploadOutlinedIcon />}
      onClick={() => upload(file)}
    >
      {loading ? "CARREGANDO" : `IMPORTAR ${file.length} REMESSA(S)`}
    </Button>
  );
};
export default UploadButton;
