import { Box, useTheme, Button, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import { useState, useEffect } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../../components/NoRowsOverlay";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as XLSX from "xlsx";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import useAxiosPrivate from "../../../api/useAxiosPrivate";

import { styled } from "@mui/material/styles";

const ClientBirthdayReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:900px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [loadingData, setLoadingData] = useState(true);
  const [clients, setClients] = useState([]);
  const [clientsFiltered, setClientsFiltered] = useState([]);

  const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  const [endDate, setEndDate] = useState(dayjs().endOf("year"));

  const monthSorting = (a, b) => {
    return dayjs(b).month - dayjs(a).month;
  };

  useEffect(() => {
    const getClients = async () => {
      const params = {
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
      };
      try {
        const response = await axiosPrivate.get("/reports/birthdays", {
          params,
        });
        setClients(response.data);
        setClientsFiltered(response.data);
        setLoadingData(false);
      } catch (err) {}
    };
    getClients();
  }, []);

  const getClientsFiltered = async () => {
    if (startDate >= endDate) {
      var dateHelper = endDate;
      setEndDate(startDate);
      setStartDate(dateHelper);
    }
    const params = {
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
    };

    try {
      const response = await axiosPrivate.get("/reports/birthdays", {
        params,
      });
      setClientsFiltered(response.data);
      setLoadingData(false);
    } catch (err) {}
  };

  const columns = [
    {
      field: "name",
      flex: 1,
      headerName: "Cliente",
      valueGetter: (params) => {
        return params.row.name;
      },
    },
    {
      field: "birthDate",
      headerName: "Data de Nascimento",
      sortComparator: monthSorting,
      flex: 0.5,
      valueGetter: (params) => {
        return params.row.birthDate
          ? dayjs(params.row.birthDate).format("YYYY-MM-DD")
          : "";
      },
    },
    {
      field: "phoneNumber",
      headerName: "Telefone",
      valueGetter: (params) => {
        return params.row.phoneNumber;
      },
    },
    {
      field: "email",
      flex: 1,
      headerName: "Email",
      valueGetter: (params) => {
        return params.row.email;
      },
    },

    {
      field: "amount",
      headerName: "Valor da Última Remessa",
      flex: 0.5,
      type: "number",
      valueGetter: (params) => {
        return params.row.amount ? `US$ ${params.value.toFixed(2)}` : "";
      },
    },
    {
      field: "lastTransaction",
      headerName: "Data da Última Transação",
      valueGetter: (params) => {
        return params.row.lastTransaction
          ? dayjs(params.row.lastTransaction).format("YYYY-MM-DD")
          : "";
      },
    },

    {
      field: "storeName",
      headerName: "Loja da Última Remessa",
      valueGetter: (params) => {
        return params.row.storeName;
      },
    },
  ];

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#62929E",
    "&:hover": {
      backgroundColor: "#546A7B",
    },
  }));

  const applyFilters = () => {
    setLoadingData(true);
    getClientsFiltered();
  };

  const clearFilters = () => {
    setClientsFiltered(clients);
    setStartDate(dayjs().startOf("year"));
    setEndDate(dayjs().endOf("year"));
  };

  const ExportData = (data) => {
    const filename = "MSBManager_report.xlsx";
    const x = data.map((client) =>
      (({
        name,
        phoneNumber,
        email,
        birthDate,
        lastTransaction,
        amount,
        storeName,
      }) => ({
        Nome: name,
        Telefone: phoneNumber,
        Email: email,
        "Data de Nascimento": dayjs(birthDate).format("YYYY-MM-DD"),
        "Última Remessa (USD)": Math.round(amount).toFixed(2),
        "Data da última Remessa": dayjs(lastTransaction).format("YYYY-MM-DD"),
        "Loja da última Remessa": storeName,
      }))(client)
    );
    var ws = XLSX.utils.json_to_sheet(x);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "msb_report");
    XLSX.writeFile(wb, filename);
  };

  return (
    <Box>
      <Box>
        <Box
          height="65vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: "#0c1c3c",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#62929E",
              borderBottom: "none",
              color: "white",
              maxHeight: "168px !important",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.grey[100],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#62929E",
              color: "white",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !Important`,
            },
            "& .super-app-theme--cell": {
              backgroundColor: "rgba(224, 183, 60, 0.55)",
              color: "#1a3e72",
              fontWeight: "600",
            },
            "& .super-app.positive": {
              backgroundColor: "rgba(157, 255, 118, 0.49)",
              color: "#1a3e72",
              fontWeight: "600",
            },
            "& .super-app.negative": {
              backgroundColor: "#d47483",
              color: "#1a3e72",
              fontWeight: "600",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              height: "unset !important",
            },
          }}
        >
          <Box
            sx={{
              p: 0.5,
              pb: 1,
              display: "grid",
              gap: "10px",
              gridTemplateColumns: "repeat(6, 1fr)",
            }}
          >
            <DatePicker
              label="Data de Início"
              value={startDate}
              //defaultValue={}
              onChange={(newValue) => setStartDate(newValue)}
              sx={{ gridColumn: isNonMobile ? "span 3" : "span 6" }}
              format="YYYY-MM-DD"
            />
            <DatePicker
              label="Data de Fim"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              sx={{ gridColumn: isNonMobile ? "span 3" : "span 6" }}
              format="YYYY-MM-DD"
            />

            <ColorButton
              variant="contained"
              onClick={applyFilters}
              sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
            >
              <FilterAltOutlinedIcon sx={{ mr: "10px" }} />
              <Typography fontWeight="bold">APLICAR FITLROS</Typography>
            </ColorButton>
            <ColorButton
              variant="contained"
              onClick={clearFilters}
              sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
            >
              <FilterAltOffOutlinedIcon sx={{ mr: "10px" }} />
              <Typography fontWeight="bold">REMOVER FITLROS</Typography>
            </ColorButton>
            <Box sx={{ gridColumn: isNonMobile ? "span 3" : "span 6" }} />
            <ColorButton
              variant="contained"
              onClick={() => ExportData(clientsFiltered)}
              sx={{ gridColumn: isNonMobile ? "span 1" : "span 3" }}
            >
              <FileDownloadOutlinedIcon sx={{ mr: "10px" }} />
              <Typography fontWeight="bold">EXPORTAR EXCEL</Typography>
            </ColorButton>
          </Box>
          <DataGrid
            loading={loadingData}
            initialState={{
              sorting: {
                sortModel: [{ field: "birthDate", sort: "asc" }],
              },
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            pageSizeOptions={[25, 50, 100]}
            getRowId={(row) => row.id}
            rows={clientsFiltered}
            columns={columns}
            slots={{
              loadingOverlay: LinearProgress,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            disableColumnMenu
          />
        </Box>
      </Box>
    </Box>
  );
};
export default ClientBirthdayReport;
